<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm8
                  md4>
                  <v-autocomplete
                    v-model="category.id"
                    :items="category.list"
                    :clearable="true"
                    :label="$t('voucherProduct.category')"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getPromotionCodes()"
              >
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card> -->
        <material-card
          :title="$t('promotionCode.listTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onCreatePromotionCode(null)"
            >
              {{ $t("promotionCode.btn.create") }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="promotionCodeHeaders"
            :items="promotionCodes"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td>{{ item.stt }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.usageTimes }}</td>
              <td>{{ item.numberOfUsed }}</td>
              <td>{{ $t(getPromotionType(item.promotionType)) }}</td>
              <td>{{ $t(getApplyType(item.applyType)) }}</td>
              <td>{{ getValuType(item.value, item.promotionType) }}</td>
              <td :class="item.remainDays < 0 ? 'text-red' : ''">{{ item.startTime }}</td>
              <td :class="item.remainDays < 0 ? 'text-red' : ''">{{ item.endTime }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onCreatePromotionCode(item)"
                        >
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.update") }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalConfirmDeletePromotionCode(item.id)"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.delete") }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="promotionCodePaginate.totalPage"
            :current-page="promotionCodePaginate.currentPage"
            :row-per-page="promotionCodePaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeletePromotionCode"
    />
    <addPromotionCodeModal
      ref="addPromotionCodeModal"
      @updateCreatePromotionSuccess="getPromotionCodes"
    />
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import AddPromotionCodeModal from './Add.vue'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
// import VoucherProductApplyType from 'enum/voucherProductApplyType'
import dateUtils from 'utils/dateUtils'
export default {
  components: {
    ConfirmModal,
    AddPromotionCodeModal
  },
  data: () => ({
    promotionCodeHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'supplier.code',
        value: 'code'
      },
      {
        sortable: false,
        text: 'promotionCode.usageTimes',
        value: 'usageTimes'
      },
      {
        sortable: false,
        text: 'evoucher.status.used',
        value: 'numberOfUsed'
      },
      {
        sortable: false,
        text: 'promotionCode.promotionType',
        value: 'promotionType'
      },
      {
        sortable: false,
        text: 'promotionCode.applyFor',
        value: 'applyType'
      },
      {
        sortable: false,
        text: 'promotionCode.reducedRate',
        value: 'value'
      },
      {
        sortable: false,
        text: 'promotionCode.startTime',
        value: 'startTime'
      },
      {
        sortable: false,
        text: 'promotionCode.endTime',
        value: 'endTime'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    promotionCodes: [],
    promotionCodePaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    promotionCodeId: null,
    confirmModalTitle: null,
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_PRODUCT_PROMOTION_CODES_DATA'
    ])
  },
  watch: {
    VOUCHER_PRODUCT_PROMOTION_CODES_DATA () {
      let res = this.VOUCHER_PRODUCT_PROMOTION_CODES_DATA
      // Handle paginate
      this.promotionCodePaginate.currentPage = res.paginate.currentPage
      this.promotionCodePaginate.totalPage = res.paginate.totalPage
      this.promotionCodePaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let productData = res.results
      this.promotionCodes = []
      for (let i = 0, size = productData.length; i < size; i++) {
        let productObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: productData[i].id,
          code: productData[i].code,
          usageTimes: productData[i].usage_times,
          usagePerAccount: productData[i].usage_per_account,
          promotionType: productData[i].promotion_type,
          applyType: productData[i].apply_type,
          value: productData[i].value,
          startTime: this.formatDate(productData[i].start_time),
          endTime: this.formatDate(productData[i].end_time),
          remainDays: dateUtils.getRemainDays(productData[i].end_time),
          numberOfUsed: productData[i].numberOfUsed,
          issuanceIds: productData[i].issuance_ids,
          workpieceId: productData[i].workpiece
        }
        this.promotionCodes.push(productObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getPromotionCodes()
  },
  methods: {
    getValuType: function (value, promotionType) {
      // eslint-disable-next-line eqeqeq
      if (promotionType == VoucherProductPromotionType.PRICE) {
        return functionUtils.convertFormatNumberToMoney(value.toString())
        // eslint-disable-next-line eqeqeq
      } else if (promotionType == VoucherProductPromotionType.PERCENT) {
        return value + '%'
      } else {
        return ''
      }
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    getApplyType: function (applyType) {
      // eslint-disable-next-line eqeqeq
      let promotionCodeApplyTypeList = stringUtils.PromotionCodeApplyTypeList
      let index = promotionCodeApplyTypeList.findIndex(x => x.value === applyType)
      return index !== -1 ? promotionCodeApplyTypeList[index].text : ''
    },
    getPromotionType: function (promotionType) {
      // eslint-disable-next-line eqeqeq
      if (promotionType == VoucherProductPromotionType.PRICE) {
        return 'promotionCode.price'
        // eslint-disable-next-line eqeqeq
      } else if (promotionType == VoucherProductPromotionType.PERCENT) {
        return 'promotionCode.percent'
      } else {
        return ''
      }
    },
    /**
     * Get voucher product
     */
    getPromotionCodes: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.promotionCodePaginate.currentPage
        }
      }
      this.GET_VOUCHER_PRODUCT_PROMOTION_CODES(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Show modal add category
     */
    onCreatePromotionCode: function (item) {
      this.$refs.addPromotionCodeModal.onShowModal(item)
    },
    /**
     * Delete domain
     */
    onDeletePromotionCode: function () {
      this.DELETE_VOUCHER_PRODUCT_PROMOTION_CODE({ id: this.promotionCodeId })
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getPromotionCodes()
          }.bind(this)
        )
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(errorText),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * Show modal confirm delete domain
     */
    onShowModalConfirmDeletePromotionCode: function (id) {
      this.promotionCodeId = id
      this.confirmModalTitle = this.$t('promotionCode.modal.confirmDeleteTitle')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.promotionCodePaginate.currentPage = page
      this.getCategories()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_PRODUCT_PROMOTION_CODES',
      'DELETE_VOUCHER_PRODUCT_PROMOTION_CODE'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
