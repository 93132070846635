<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="form.id ? $t('promotionCode.btn.update') : $t('promotionCode.btn.create')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <!-- Code -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="form.code"
                  :label="$t('promotionCode.btn.create')"
                  :hint="$t('promotionCode.form.enterPromoCodeHere')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- usageTimes -->
              <v-flex
                xs6
                md3>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="form.usageTimes"
                  :label="$t('promotionCode.usageTimes')"
                  :name="$t('promotionCode.usageTimes')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- usagePerAccount -->
              <v-flex
                xs6
                md3>
                <v-text-field
                  v-model="form.usagePerAccount"
                  :label="$t('promotionCode.usagePerAccount')"
                  :name="$t('promotionCode.usagePerAccount')"
                  type="text"
                />
              </v-flex>
              <!-- Promotion Type -->
              <v-flex
                xs12
                sm6
                md6>
                <v-select
                  :rules="[ruleRequiredValue]"
                  v-model="form.promotionType"
                  :items="promotionCodeTypeList"
                  :label="$t('promotionCode.promotionType')"
                  :disabled="form.id ? true : false"
                  class="required"
                  @change="onPromotionTypeChange"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <!-- Value -->
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="form.valueFormatter"
                  :label="$t('promotionCode.reducedRate') + (form.promotionType == VoucherProductPromotionType.PRICE ? ' (VND)' : ' (%)')"
                  :name="$t('promotionCode.reducedRate')"
                  :disabled="form.id ? true : false"
                  type="text"
                  class="required"
                  @input="formatValue"
                />
              </v-flex>
              <!-- Application Type -->
              <v-flex
                xs12
                sm6
                md6>
                <v-select
                  :rules="[ruleRequiredValue]"
                  v-model="form.applyType"
                  :items="promotionCodeApplyTypeList"
                  :label="$t('promotionCode.applyFor')"
                  :disabled="form.id ? true : false"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-layout
                  wrap
                  layout
                >
                  <v-flex
                    xs12
                    sm6
                    md6>
                    <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      lazy
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="startDateFormatted"
                          :label="$t('createVoucher.fromDate')"
                          persistent-hint
                          class="required"
                          @blur="
                            date = parseDate(startDateFormatted)
                          "
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :min="minStartDate"
                        no-title
                        @input="menuStartDate = false"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6>
                    <v-menu
                      ref="endStartDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      lazy
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="endDateFormatted"
                          :label="$t('createVoucher.toDate')"
                          persistent-hint
                          class="required"
                          @blur="
                            date = parseDate(endDateFormatted)
                          "
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :min="minEndDate"
                        no-title
                        @input="menuEndDate = false"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                v-if="form.applyType == promotionCodeApplyTypeEnum.VOUCHER"
                xs12
                md12>
                <v-layout wrap>
                  <!-- Start date -->
                  <v-flex
                    xs12
                    md12>
                    <v-autocomplete
                      v-model="form.workpieceId"
                      :items="workpieceList"
                      :clearable="true"
                      :label="$t('assignVoucher.selectTheWorkPieceCode')"
                      type="text"
                      multiple
                      chips
                      small-chips
                      @change="onChangeSelectedWorkpiece()"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          :selected="item.selected"
                          close
                          class="chip--select-multi"
                          @input="removeVoucherIssuance(item, index)"
                        >
                          {{ item.text }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <span>{{ $t(data.item.text) }} <span class="grey--text font-small">[{{ $t(data.item.code) }}]</span></span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <!-- End date -->
                  <v-flex
                    xs12
                    md12>
                    <v-autocomplete
                      v-model="form.issuanceIds"
                      :items="issuanceList"
                      :clearable="true"
                      :label="$t('assignVoucher.chooseIssuance')"
                      multiple
                      chips
                      small-chips
                      type="text"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          :selected="item.selected"
                          close
                          class="chip--select-multi"
                          @input="doRemoveIssuance(item, index)"
                        >
                          {{ concatSuffixPrefix(item) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <span>{{ concatSuffixPrefix(data.item) }}</span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import stringUtils from 'utils/stringUtils'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import ToastType from 'enum/toastType'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
export default {
  data: () => ({
    form: {
      promotionType: 1,
      applyType: 1,
      code: null,
      usageTimes: null,
      usagePerAccount: null,
      value: null,
      id: null,
      valueFormatter: null,
      workpieceId: [],
      issuanceIds: []
    },
    valid: false,
    isShowModal: false,
    VoucherProductPromotionType: VoucherProductPromotionType,
    promotionCodeTypeList: stringUtils.PromotionCodeTypeList,
    promotionCodeApplyTypeList: stringUtils.PromotionCodeApplyTypeList,
    // Start issue expired date to customer
    startDate: moment().format('YYYY-MM-DD'),
    startDateFormatted: moment().format('DD/MM/YYYY'),
    menuStartDate: false, // End issue expired date
    minStartDate: moment().format('YYYY-MM-DD'),
    // Start issue expired date to customer
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    endDateFormatted: moment().add(1, 'days').format('DD/MM/YYYY'),
    menuEndDate: false, // End issue expired date
    minEndDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    workpieceList: [],
    issuanceList: [],
    promotionCodeApplyTypeEnum: VoucherProductApplyType
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_ISSUANCES_DATA',
      'GET_ISSUANCE_LIST_DATA'
    ])
  },
  watch: {
    /**
     * Issue expired date to customer
     */
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    /**
     * Issue expired date to customer
     */
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    VOUCHER_ISSUANCES_DATA () {
      let data = this.VOUCHER_ISSUANCES_DATA
      this.workpieceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          text: data[i].name,
          code: data[i].code,
          value: data[i].id
        }
        this.workpieceList.push(obj)
      }
    },
    GET_ISSUANCE_LIST_DATA () {
      let data = this.GET_ISSUANCE_LIST_DATA
      this.issuanceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          prefix: data[i].prefix,
          suffix: data[i].suffix,
          workpieceId: data[i].workpiece_id
        }
        this.issuanceList.push(obj)
      }
    }
  },
  created () {
    this.getWorkpieceList()
  },
  methods: {
    /**
     * Promotion type change
     */
    onPromotionTypeChange: function (promotionType) {
      // Reset value when change discount type
      this.form.value = null
      this.form.valueFormatter = null
    },
    /**
     * Format value
     */
    formatValue: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      // eslint-disable-next-line eqeqeq
      if (this.form.promotionType == VoucherProductPromotionType.PRICE) {
        this.form.valueFormatter = functionUtils.convertFormatNumberToMoney(
          value
        )
      } else {
        this.form.valueFormatter = value
      }
      this.form.value = value
    },
    // Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    concatSuffixPrefix: function (suffixPrefix) {
      return functionUtils.concatSuffixPrefix(suffixPrefix)
    },
    onShowModal: function (item) {
      if (item) {
        this.form.id = item.id
        this.form.value = item.value
        // eslint-disable-next-line eqeqeq
        if (item.promotionType == VoucherProductPromotionType.PRICE) {
          this.form.valueFormatter = functionUtils.convertFormatNumberToMoney(item.value.toString())
        } else {
          this.form.valueFormatter = item.value
        }
        this.form.promotionType = item.promotionType
        this.form.applyType = item.applyType
        this.form.code = item.code
        this.form.usageTimes = item.usageTimes
        this.form.usagePerAccount = item.usagePerAccount
        this.startDate = dateUtils.formatCompareDate(item.startTime)
        this.startDateFormatted = item.startTime
        this.endDate = dateUtils.formatCompareDate(item.endTime)
        this.endDateFormatted = item.endTime
        this.form.issuanceIds = item.issuanceIds
        this.form.workpieceId = item.workpieceId
        this.onChangeSelectedWorkpiece()
      } else {
        this.form.id = null
        this.form.value = null
        this.form.valueFormatter = null
        this.form.promotionType = 1
        this.form.applyType = 1
        this.form.code = null
        this.form.usageTimes = null
        this.form.usagePerAccount = null
        // Start date
        this.startDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
        this.startDateFormatted = moment().format('DD/MM/YYYY')
        this.minStartDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
        // End date
        this.endDate = moment().add(1, 'days').format(dateUtils.STATIC_FORMAT_DATE_SQL)
        this.endDateFormatted = moment().add(1, 'days').format('DD/MM/YYYY')
        this.minEndDate = moment().add(1, 'days').format(dateUtils.STATIC_FORMAT_DATE_SQL)
        this.form.issuanceIds = []
        this.form.workpieceId = []
      }
      this.isShowModal = true
    },
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        if (this.form.applyType === this.promotionCodeApplyTypeEnum.VOUCHER) {
          if (this.form.issuanceIds.length === 0) {
            this.ON_SHOW_TOAST({
              'message': this.$t('assignVoucher.chooseIssuance'),
              'styleType': ToastType.ERROR
            })
            return
          }
        }
        let startDate = dateUtils.formatSqlDate(this.startDateFormatted)
        let endDate = dateUtils.formatSqlDate(this.endDateFormatted)
        let filter = {
          startTime: startDate,
          endTime: endDate,
          value: this.form.value,
          code: this.form.code,
          promotionType: this.form.promotionType,
          usageTimes: this.form.usageTimes,
          usagePerAccount: functionUtils.isEmptyString(this.form.usagePerAccount) ? null : this.form.usagePerAccount,
          applyType: this.form.applyType,
          issuanceIds: this.form.issuanceIds
        }
        if (this.form.id) {
          this.UPDATE_VOUCHER_PRODUCT_PROMOTION_CODE({ id: this.form.id, ...filter }).then(
            function () {
              this.isShowModal = false
              this.$emit('updateCreatePromotionSuccess')
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        } else {
          this.CREATE_VOUCHER_PRODUCT_PROMOTION_CODE(filter).then(
            function () {
              this.isShowModal = false
              this.$emit('updateCreatePromotionSuccess')
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        }
      }
    },
    onCloseModal: function () {
      this.isShowModal = false
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * E voucher issuance change
     */
    onChangeSelectedWorkpiece: function () {
      let filter = {
        params: {
          workpieceIdList: this.form.workpieceId
        }
      }
      this.GET_ISSUANCE_LIST(filter)
      // this.onUpdateIssuanceIdList()
    },
    /**
     * Get e voucher issuance list
     */
    getWorkpieceList: function () {
      let filter = {
        params: {
          excludePromotion: true
        }
      }
      this.GET_VOUCHER_ISSUANCE_LIST(filter)
    },
    removeVoucherIssuance (item, index) {
      this.form.workpieceId.splice(index, 1)
      this.onUpdateIssuanceIdList()
      this.onChangeSelectedWorkpiece()
    },
    onUpdateIssuanceIdList: function () {
      let removeIssuanceIds = this.issuanceList.filter(x =>
        this.form.workpieceId.includes(x.workpieceId) && this.form.issuanceIds.includes(x.value)
      ).map(x => x.value)
      this.form.issuanceIds = removeIssuanceIds
    },
    doRemoveIssuance (item, index) {
      this.form.issuanceIds.splice(index, 1)
    },
    ...mapActions([
      'CREATE_VOUCHER_PRODUCT_PROMOTION_CODE',
      'UPDATE_VOUCHER_PRODUCT_PROMOTION_CODE',
      'ON_SHOW_TOAST',
      'GET_ISSUANCE_LIST',
      'GET_VOUCHER_ISSUANCE_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
